define("discourse/plugins/discourse-wikimedia-auth/discourse/components/wiki-username", ["exports", "@ember/component", "@ember/runloop", "@ember/service", "jquery", "discourse-common/utils/decorators"], function (_exports, _component, _runloop, _service, _jquery, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    siteSettings: (0, _service.inject)(),
    classNameBindings: [":wiki-username"],
    tagName: "h2",
    didInsertElement() {
      this._super(...arguments);
      (0, _runloop.schedule)("afterRender", () => {
        const $el = (0, _jquery.default)(this.element);
        $el.insertAfter(".full-name");
        (0, _jquery.default)(".full-name").toggleClass("add-margin", Boolean(this.user.name));
      });
    },
    wikiUserUrl(wikiUsername) {
      return `${this.siteSettings.wikimedia_auth_site}/wiki/User:${wikiUsername}`;
    }
  }, [["method", "wikiUserUrl", [(0, _decorators.default)("user.wiki_username")]]]));
});