define("discourse/plugins/discourse-wikimedia-auth/discourse/templates/connectors/create-account-before-modal-body/create-account-description-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="create-account-description">
    {{i18n "create_account.description"}}
  </div>
  */
  {
    "id": "HzsEQByG",
    "block": "[[[10,0],[14,0,\"create-account-description\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"create_account.description\"],null]],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-wikimedia-auth/discourse/templates/connectors/create-account-before-modal-body/create-account-description-container.hbs",
    "isStrictMode": false
  });
});